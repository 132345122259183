import React from 'react';
import PropTypes from 'prop-types';
import { Element } from 'rc-scroll-anim';
import downLoad from '../assets/imgs/downLoad.png';
import bannerImg from '../assets/imgs/bannerImg.png';
import bannerImgMobile from '../assets/imgs/mobile/bannerImg.png';
class Banner extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    navToShadow: PropTypes.func,
  }
  static defaultProps = {
    className: 'banner',
  }
  constructor(props) {
    super(props)
    this.state = {
      appUrl: '',
    }
  }
  componentDidMount(){
    console.log(window.location.origin)
    // fetch('https://www.WePeso.com/api/download/page/config?hash=2',{
    fetch( window.location.origin + '/api/download/page/config?hash=2',{
      method:'GET',
      headers:{'Content-Type':'application/json;charset=UTF-8'},
      mode:'no-cors',
      cache:'default'
    })
    .then(res =>res.json())
    .then((res) => {
      if(res.ret === 0) {
        const { data={} } = res;
        this.setState({
          appUrl: data.url,
        })
      }
    }) 
  }

  render() {
    const { className, navToShadow, isMobile } = this.props;
    const { appUrl, andro } = this.state;
    // // const appUrl = 'https://okpeso.onelink.me/KfYS/ADist';
    // const appUrl = '1212121';
    // console.log('----appUrl----', appUrl)
    console.log(isMobile,'isMobile');
    return (
      <Element id="home" component="section" className={(isMobile ? 'block-mobile ': '')}  onChange={navToShadow}>
        <div className={className + (isMobile ? '-mobile ': '')}>
          <div className={`bgWrapper`}>
              <div className={`content`}>
                <div className={`title`}>{'¡Le Ayudamos A Obtener Un Préstamo Más Fácilmente!'}</div>
                <div className={`subtitle`}>{'En 4 Pasos Sencillos, Obtenga Su Dinero En Un Plazo De 2 A 24 Horas.'}</div>
                <div className={`desc`}>{'Registrarse • Enviar Información • Solicitar • Retirarse'}</div>
                
                <a target="_blank" rel="noopener noreferrer" href={andro} key="a" className={`word-download`}>
                  <button className="banner-btn"><a  style={{color: '#FFFFFF'}} target="_blank" href={appUrl} key="a" rel="noopener noreferrer"><img className="banner-img" src={downLoad} alt='downLoad'/> Descargar CoriMoney</a></button>
                </a>
             
              </div>
              <div className={`imgWrapper`}>
                <img src={isMobile ? bannerImgMobile: bannerImg} alt="img" />
              </div>  
          </div>
        </div>
      </Element>
    );
  }
}

export default Banner;
